<template>
    <div ref="chartContainer" class="chart" style="height: 100%; position: relative;">
    </div>
    <ExportSettingsDialog 
        :algo="'something2'"
        @exportToSvg="exportToSvgEmit"
    />
</template>

<script>

import Anychart from 'anychart';
import ExportSettingsDialog from './ExportSettingsDialog.vue'

import './AnychartThemes/market_forecast.js';

export default {
    props: [
        'years',
        'data',
        'valueDisplayOption',
        'topicName',
        'segmentationName',
        'segmentationItemsFullName',
        'exportToWordSummary',
        'index1',
    ],
    emits: ['next-step','addChartsForExportWord'],
    name: 'VueAnychartFigure2',
    components:{
        ExportSettingsDialog
    },
    data() {
        return {
            chart: null,
            column: null,
            maxValue:null,
            fontSize:10,
        }
    },

    mounted() {
        this.init();
        this.$emit('next-step');
        if(this.exportToWordSummary){
            this.addChartsForWord()
        }

    },
    computed: {
        topic() {
            return this.$store.getters.obtainTopic;
        },
    },
    methods: {
        removeSeries() {
            if (this.chart.getSeriesCount()) {
                this.chart.removeSeriesAt(0);
            }
        },
        removeAllSeries() {
            if (this.chart.getSeriesCount()) {
                this.chart.removeAllSeries();
            }
        },
        addSeries(data) {
            this.delegateMethod('addSeries', data);
        },
        // delegateMethod(name, data) {
        //     if (!this.chart) {
        //         warn(`Cannot call [$name] before the chart is initialized. Set prop [options] first.`, this);
        //         return
        //     }

        //     return this.chart[name](data)
        // },

        init(){
            let _Anychart = this.Anychart || Anychart; 
            this.chart = _Anychart; 
            
            let dataSet = this.chart.data.set(this.data); 

            // FY - First Year
            // LY - Last Year
            // map data for the first series, take x from the zero column and value from the first column of data set
            let SeriesFYLY = dataSet.mapAs({x:0, value: 1});

            // Create column chart
            this.column = this.chart.column();

            // LICENCE KEY
            this.chart.licenseKey('marketforecast.com-f857f242-9dccc95c'); 
            
            // Apply themes
            this.chart.theme('marketForecast');

            const currentYear = new Date().getFullYear();

            // Credits
            this.column
                .credits()
                .enabled(true)
                .url(
                   'https://www.marketforecast.com'
                )
                .logoSrc('https://www.marketforecast.com/favicon.ico')
                .text(
                    `© Market Forecast ${currentYear}`
                ); 

            // Title
            // column title // hide column Title
            // this.column
            //     .title()
            //     .enabled(true)
            //     .useHtml(true)
            //     .padding([0,0,10,0])
            //     .text(
            //         `<span style="font-size: 16px;font-weight:bold;">${this.topicName} Market Size by ${this.segmentationName}</span>` +
            //         '<br/><span  style="color:#929292; font-size: 12px;">' +
            //         `Market Size ${this.years[0]}-${this.years[1]}</span>`
            //     );
            
            // set chart margin settings
            // this.column.padding(20, 20, 10 ,20); 
            this.column.padding(20, 5, 5, 5);

            // grid Settings
            this.column
                .yGrid(true)
                .xGrid(true)
                .xMinorGrid(true)
                .yMinorGrid(true);

            // set chart legend settings
            this.column.legend().enabled(true).padding({ top: 10, bottom: 30 });  
            this.column.legend().position("bottom");

            // enabling stagger mode
            this.column.xAxis().staggerMode(true);
            // this.column.xAxis().staggerLines(2);
            this.column.xAxis().staggerMaxLines(4);
                    
            this.column.yAxis(0)
            this.column.yAxis(0)
                .title(`Market size in US$ ${this.valueDisplayOption}`)
                .title().padding(20)
                .fontColor("#385D8A"); // Change color of Y-axis title
            
            this.column.yAxis(0)
                .labels()
                .format(function() {
                    return Anychart.format.number(this.value, {
                        groupsSeparator: ','
                    });
                });
            
            // setting Grid Settings
            this.column.xGrid(true);
            this.column.yGrid(true);

            // setting and adjusting data visualization 
            
            let columnSeriesFYLY = this.column.column(SeriesFYLY);
            columnSeriesFYLY.name(`${this.years[0]}-${this.years[1]}`);

            // columnSeriesFYLY.normal().fill("#385D8A", 0.7);
            columnSeriesFYLY.normal().fill("#385D8A", 1);
            // columnSeriesFYLY.hovered().fill("#385D8A", 0.5);
            columnSeriesFYLY.hovered().fill("#385D8A", 0.8);
            // columnSeriesFYLY.selected().fill("#385D8A", 0.9);  
            columnSeriesFYLY.selected().fill("#385D8A", 0.6);  

            columnSeriesFYLY.normal().stroke("#ffffff", 1, "", "");
            columnSeriesFYLY.hovered().stroke("#ffffff", 1, "", "");
            columnSeriesFYLY.selected().stroke("#ffffff", 1, "", "");

            

            // Set gloabal tooltip styles
            // this.column.tooltip().background().fill("#C0C0C0"); // Set tooltip background color
  
            // this.column.tooltip().title().fontColor("#003A70"); // Set tooltip text color
            this.column.tooltip().title().fontColor("#FFFFFF"); // Set tooltip text color
            this.column.tooltip().title().fontSize(14); // Set tooltip font size

            this.column.tooltip() // customize tooltipTitle
                .titleFormat(
                    function(items){
                        return this.segmentationItemsFullName[items.index]
                    }.bind(this)
                )

            // this.column.tooltip().fontColor("#003A70"); // Set tooltip text color
            this.column.tooltip().fontColor("#FFFFFF"); // Set tooltip text color
            this.column.tooltip().fontSize(12); // Set tooltip font size
            this.column.tooltip().padding(10); // Set tooltip padding

            // create variables to assign tooltip 
            const firstYear =this.years[0]
            const lastYear = this.years[1]
            const valueDisplayOption = this.valueDisplayOption

            // Customize the tooltip for column Series2024 to include custom text and formatted value
            columnSeriesFYLY.tooltip()
                .format(
                    function() {
                        return `Market size ${firstYear}-${lastYear}: ` + Anychart.format.number(this.value, 
                            { 
                                groupsSeparator: ',', 
                                decimalSeparator: '.' 
                            }
                        ) + ` US$ ${valueDisplayOption}`;
                    }
                );

            // this.column.container(this.$el).draw();
            this.column.container(this.$refs.chartContainer).draw();

            this.column.contextMenu().itemsFormatter(
                function(items){
                    // modify save as png
                    items['save-chart-as'].subMenu['save-chart-as-png'].action = function() {
                        this.column.saveAsPng(
                            {
                                "width": 1000,
                                "height": 1000,
                                "quality": 0.3,
                                "filename":'SS02_'+this.topicName+'_Market_Size_by_'+this.segmentationName,
                            }
                        );
                    }.bind(this); 
                    // modify save as jpg
                    items['save-chart-as'].subMenu['save-chart-as-jpg'].action = function() {
                        this.column.saveAsJpg(
                            {
                                "width": 1000,
                                "height": 1000,
                                "quality": 0.3,
                                "forceTransparentWhite": false,
                                "filename":'SS02_'+this.topicName+'_Market_Size_by_'+this.segmentationName,
                            }
                        );
                    }.bind(this); 

                    // modify save as pdf
                    items['save-chart-as'].subMenu['save-chart-as-svg'].action = function() {
                        this.column.saveAsSvg(
                            // {
                            //     "paperSize": 'a4',
                            //     "landscape": true,
                            // }
                            {
                                "width": 1000,
                                "height": 1000,
                                "filename":'SS02_'+this.topicName+'_Market_Size_by_'+this.segmentationName,
                            }
                        );
                    }.bind(this); 


                    // modify save as pdf
                    items['save-chart-as'].subMenu['save-chart-as-pdf'].action = function() {
                        this.column.saveAsPdf(
                            {
                                "paperSize": 'a4',
                                "landscape": true,
                                "filename":'SS02_'+this.topicName+'_Market_Size_by_'+this.segmentationName,
                            }
                        );
                    }.bind(this); 
                    return items;
                }.bind(this)
            )


            this.chart.exports.filename('SS02_'+this.topicName+'_Market_Size_by_'+this.segmentationName); //Change name of exports
        },

        addChartsForWord(){
            this.column.width(600);
            this.column.height(400);
            this.column.background().stroke(null);

            // EXPORT CHART TO SVG WITH CUSTOMIZE SIZE 
            let svg = this.column.toSvg();

            // CONVERT SVG STRING TO AN DOM OBJECT 
            let parser= new DOMParser();
            let svgDoc = parser.parseFromString(svg, 'image/svg+xml');

            // get Root <svg>
            let svgElement = svgDoc.documentElement;

            // change width and height
            // change width and height 
            svgElement.setAttribute('width', '600');
            svgElement.setAttribute('height', '400');

            // serialize svg to string 
            let serializer = new XMLSerializer();
            let svgString = serializer.serializeToString(svgElement);

            const exportContent = {
                // content: this.column.toSvg(),
                content: svgString,
                name: 'singleSegmentationSummary '+'Figure 2',
                segmentation: this.segmentationName,
                order: this.index1
            } ;
            this.$emit("addChartsForExportWord", {exportContent: exportContent, order:exportContent.order})    
        },

        exportToSvgEmit({width, height}){
            this.exportToSvg(width, height)
        },

        exportToSvg(width, height){
            this.column.saveAsSvg(
                {
                    "width":width,
                    "height":height,
                    "filename":'SS02_'+this.topicName+'_Market_Size_by_'+this.segmentationName,
                }
            )
        }

    },
    watch: {
        // 'data': {
        //     async handler(newVal) {
        //         if (newVal) {
        //             // console.log('outputFrom Chart Change', newVal);
        //             if (this.chart) {
        //                 this.column.dispose();
        //                 this.chart = null;
        //                 this.column = null;
        //                 this.maxValue =null;
        //                 this.init();
        //             }
        //         }
        //     },
        //     deep: true,
        // },
    },
    beforeUnmount() {
        if (this.chart) {
            this.column.dispose();
            this.chart = null;
            this.column = null;
            this.maxValue = null;
        }
    }


}

</script>